import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Learning.css";

const learningTopics = [
  { name: "Foundations", path: "/learning/foundations", icon: "🏛" },
  { name: "Saving & Budgeting", path: "/learning/saving-spending-budgeting", icon: "💰" },
  { name: "Managing Finances", path: "/learning/managing-finances", icon: "📊" },
  { name: "Credit & Debt", path: "/learning/credit-debt", icon: "💳" },
  { name: "Investment Concepts", path: "/learning/investment-concepts", icon: "📈" },
  { name: "Types of Investments", path: "/learning/types-investments", icon: "🏡" },
  { name: "Risk Management", path: "/learning/risk-management", icon: "⚖" },
  { name: "Advanced Investments", path: "/learning/advanced-investments", icon: "🚀" },
  { name: "Retirement", path: "/learning/retirement", icon: "🌅" }
];

const lowResImage = "/assets/minimal-stocks-lowres.webp"; // ✅ Low-quality preview
const highResImage = "/assets/minimal-stocks.webp"; // ✅ High-resolution image

const Learning = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = highResImage;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div className={`learning-page ${imageLoaded ? "loaded" : ""}`}>
      <div className="learning-container">
        <h1 className="section-title">Learning</h1>
        <p className="section-description">
          This section explores key financial literacy topics. Each topic is structured in chronological order to help you build a strong financial foundation step by step.
        </p>
        <div className="dynamic-nav">
          {learningTopics.map((topic) => (
            <Link 
              key={topic.path} 
              to={topic.path} 
              className="nav-item"
              onClick={() => window.scrollTo(0, 0)} // ✅ Ensures smooth navigation experience
            >
              <span className="icon">{topic.icon}</span> {topic.name}
            </Link>
          ))}
        </div>
        <p className="instruction-text">Click a topic to explore.</p>
      </div>
    </div>
  );
};

export default Learning;
