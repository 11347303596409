import React, { useState } from "react";
import supabase from "../../supabaseConfig";
import "../../styles/Subsections.css";

const QASubmission = () => {
  const [question, setQuestion] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitQuestion = async () => {
    if (!question.trim()) {
      alert("Please enter a question.");
      return;
    }

    try {
      setIsSubmitting(true);

      const { error } = await supabase.from("questions").insert([
        {
          question,
          approved: false,  // Needs admin approval before showing
          upvotes: 0,
          answer: "",
        }
      ]);

      if (error) throw error;

      setQuestion("");
      alert("Question submitted for approval.");
    } catch (error) {
      console.error("Error submitting question:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Submit a Question</h1>
      <p className="qa-submission-info">
        Have a question? Submit it here and, once approved, it will appear in the Q&A section.
      </p>
      <div className="qa-form">
        <textarea
          className="qa-textarea"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Type your question here..."
          rows="6"
        />
        <button
          className={`qa-submit-button ${isSubmitting ? "disabled" : ""}`}
          onClick={submitQuestion}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit Question"}
        </button>
      </div>
    </div>
  );
};

export default QASubmission;
