import React from "react";
import { Routes, Route } from "react-router-dom"; // ❌ No extra <Router> here!
import Navbar from "./components/Navbar";
import LandingPage from "./pages/LandingPage";
import Introduction from "./pages/Introduction";
import Learning from "./pages/Learning";
import PracticalApplications from "./pages/PracticalApplications";
import QA from "./pages/QA";
import Socials from "./pages/Socials";

// Import all Learning subsections
import Foundations from "./pages/learning/Foundations";
import SavingSpendingBudgeting from "./pages/learning/SavingSpendingBudgeting";
import ManagingFinances from "./pages/learning/ManagingFinances";
import CreditDebt from "./pages/learning/CreditDebt";
import InvestmentConcepts from "./pages/learning/InvestmentConcepts";
import TypesInvestments from "./pages/learning/TypesInvestments";
import RiskManagement from "./pages/learning/RiskManagement";
import AdvancedInvestments from "./pages/learning/AdvancedInvestments";
import Retirement from "./pages/learning/Retirement";

// Import Practical Applications subsections
import CreditCards from "./pages/practical/CreditCards";
import Options from "./pages/practical/Options";
import Gold from "./pages/practical/Gold";
import Debt from "./pages/practical/Debt";
import ResearchTerminal from "./pages/practical/ResearchTerminal";

// Import Q&A subsections
import QASubmission from "./pages/qa/QASubmission";
import QAList from "./pages/qa/QAList";
import QAAnswer from "./pages/qa/QAAnswer";

// ✅ Import Preloading Hook
import usePreloadImages from "./hooks/usePreloadImages";

// ✅ Import Vercel Analytics
import { Analytics } from "@vercel/analytics/react";

// ✅ List of Background Images to Preload
const backgroundImages = [
  "/assets/minimal-stocks.webp",
  "/assets/minimal-cc.webp",
  "/assets/minimal-qa.webp",
  "/assets/minimal-city.webp",
];

const App = () => {
  usePreloadImages(backgroundImages); // ✅ Preloads images when the app loads

  return (
    <>
      <Navbar /> {/* ✅ Keep Navbar */}
      <Routes>
        {/* Main Sections */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/introduction" element={<Introduction />} />
        <Route path="/learning" element={<Learning />} />
        <Route path="/practical-applications" element={<PracticalApplications />} />
        <Route path="/qa" element={<QA />} />
        <Route path="/socials" element={<Socials />} />

        {/* Learning Subsections */}
        <Route path="/learning/foundations" element={<Foundations />} />
        <Route path="/learning/saving-spending-budgeting" element={<SavingSpendingBudgeting />} />
        <Route path="/learning/managing-finances" element={<ManagingFinances />} />
        <Route path="/learning/credit-debt" element={<CreditDebt />} />
        <Route path="/learning/investment-concepts" element={<InvestmentConcepts />} />
        <Route path="/learning/types-investments" element={<TypesInvestments />} />
        <Route path="/learning/risk-management" element={<RiskManagement />} />
        <Route path="/learning/advanced-investments" element={<AdvancedInvestments />} />
        <Route path="/learning/retirement" element={<Retirement />} />

        {/* Practical Applications Subsections */}
        <Route path="/practical-applications/credit-cards" element={<CreditCards />} />
        <Route path="/practical-applications/options" element={<Options />} />
        <Route path="/practical-applications/gold" element={<Gold />} />
        <Route path="/practical-applications/debt" element={<Debt />} />
        <Route path="/practical-applications/researchterminal" element={<ResearchTerminal />} />

        {/* Q&A Subsections */}
        <Route path="/qa/submit" element={<QASubmission />} />
        <Route path="/qa/view" element={<QAList />} />
        <Route path="/qa/answered" element={<QAAnswer />} />
      </Routes>
      <Analytics /> {/* ✅ Add Analytics here */}
    </>
  );
};

export default App;










