import React from "react";
import { Link } from "react-router-dom";
import "../styles/LandingPage.css"; // ✅ Import only its styles
import gifBackground from "../assets/background480.mp4"; // ✅ Background video import

const LandingPage = () => {
  return (
    <div className="landing-page">
      {/* ✅ Background Video - No interference */}
      <video className="background-video" autoPlay loop muted playsInline>
        <source src={gifBackground} type="video/mp4" />
      </video>

      {/* ✅ Hero Section - Isolated Styling */}
      <div className="hero">
        <h1>Modern Money Methods</h1>
        <p>Unlock financial freedom with the right knowledge and tools.</p>
        <Link to="/introduction" className="cta-button">Get Started</Link>
      </div>
    </div>
  );
};

export default LandingPage;
