import React from "react";
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const Options = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Options Trading</h1>

      <section>
        <h2>What is an Option?</h2>
        <p>
          At its core, an option is a financial contract that gives the buyer the
          right, but not the obligation, to buy or sell a stock at a predetermined price
          (strike price) before or on a specified date (expiration date).
        </p>
        <p>
          Options are a form of derivative, meaning their value is tied to the price of
          the underlying stock. They can be used for speculation, hedging risk, or
          generating income—but without understanding them, they can also be a quick way
          to lose money.
        </p>
      </section>

      <section>
        <h2>Two Types of Options</h2>

        <h3>Call Options (Bullish)</h3>
        <p>
          A call option gives the buyer the right to buy a stock at the strike price
          before expiration. Traders buy call options when they expect the stock price to
          rise.
        </p>
        <p><strong>Example:</strong></p>
        <ul>
          <li>A stock is trading at $100.</li>
          <li>You buy a call option with a $110 strike price expiring in one month.</li>
          <li>If the stock rises to $120, you can buy it at $110, making a $10 profit per share (excluding fees).</li>
          <li>If the stock does not reach $110, the option expires worthless, and you lose the premium paid.</li>
        </ul>

        <h3>Put Options (Bearish)</h3>
        <p>
          A put option gives the buyer the right to sell a stock at the strike price
          before expiration. Traders buy put options when they expect the stock price to
          fall.
        </p>
        <p><strong>Example:</strong></p>
        <ul>
          <li>A stock is trading at $100.</li>
          <li>You buy a put option with a $90 strike price expiring in one month.</li>
          <li>If the stock drops to $80, you can sell it at $90, making a $10 profit per share.</li>
          <li>If the stock stays above $90, the option expires worthless, and you lose the premium paid.</li>
        </ul>
      </section>

      <section>
        <h2>Basic Terms to Know</h2>
        <ul>
          <li><strong>Strike Price:</strong> The price at which you can buy (call) or sell (put) the stock.</li>
          <li><strong>Premium:</strong> The cost of purchasing the option contract.</li>
          <li><strong>Expiration Date:</strong> The last day the option can be exercised.</li>
          <li><strong>In The Money (ITM):</strong> The option has intrinsic value (e.g., call option when stock price &gt; strike price).</li>
          <li><strong>Out of The Money (OTM):</strong> The option has no intrinsic value (e.g., call option when stock price &lt; strike price).</li>
        </ul>
      </section>

      <section>
        <h2>How Option Premiums Are Calculated and What Affects Them</h2>

        <h3>Implied Volatility (IV)</h3>
        <p>
          Implied Volatility (IV) represents how much the market expects the stock to
          move over time. Higher IV means larger expected price swings, making options
          more expensive.
        </p>

        <h3>How IV Affects Option Prices</h3>
        <p><strong>Higher IV → Higher Option Premiums</strong></p>
        <ul>
          <li>When IV is high, option prices increase because there’s a greater chance of large price swings before expiration.</li>
          <li>This benefits option sellers since they collect higher premiums but increases costs for buyers.</li>
        </ul>

        <p><strong>Lower IV → Lower Option Premiums</strong></p>
        <ul>
          <li>When IV is low, option prices decrease due to expected smaller price swings.</li>
          <li>This benefits option buyers as contracts are cheaper, but sellers collect lower premiums.</li>
        </ul>
      </section>

      <section>
        <h2>The Greeks: Key Metrics in Option Pricing</h2>
        <ul>
          <li><strong>Delta (Δ) – Price Sensitivity:</strong> Measures how much the option price moves relative to the stock.</li>
          <li><strong>Gamma (Γ) – Stability of Delta:</strong> Measures how much delta changes when the stock moves.</li>
          <li><strong>Theta (Θ) – Time Decay:</strong> Measures how an option loses value as expiration nears.</li>
          <li><strong>Vega (ν) – Sensitivity to IV:</strong> Measures how an option’s price changes with volatility.</li>
          <li><strong>Rho (ρ) – Sensitivity to Interest Rates:</strong> Measures how much an option’s value changes with interest rates.</li>
        </ul>
      </section>

      <section>
        <h2>Writing Options: Selling Options for Income</h2>
        <p>
          Most people think of buying options—but you can also sell them to generate income. When you sell an option, you collect a premium upfront but take on the obligation to either buy or sell the stock if assigned.
        </p>

        <h3>Cash-Secured Puts (CSPs) – Selling a Put Option</h3>
        <p>
          A cash-secured put means selling a put option on a stock you’d be willing to
          own, while keeping enough cash in your account to buy the shares if assigned.
        </p>

        <h3>Covered Calls – Selling a Call Option</h3>
        <p>
          A covered call is when you sell a call option on a stock you already own. You
          collect the premium in exchange for agreeing to sell your shares if the stock
          price reaches the strike price.
        </p>
      </section>

      <section>
        <h2>The Option Wheel Strategy</h2>
        <h3>Step 1: Sell a Cash-Secured Put</h3>
        <p>
          Sell a put option at a strike price where you’re comfortable buying the stock.
          If the stock stays above the strike price, you keep the premium and repeat the
          process.
        </p>

        <h3>Step 2: Sell Covered Calls</h3>
        <p>
          After being assigned shares from the put option, sell a covered call at a
          strike price above your cost basis. If the stock price stays below the call
          strike, you keep the shares and the premium. If the stock rises above the call
          strike, your shares are sold at a profit, completing the wheel.
        </p>
      </section>

      <section>
        <h2>Summary</h2>
        <p>
          Options trading can be an effective tool for both speculation and generating
          passive income. Whether you are buying options for leverage or selling them for
          steady income, understanding risk and strategy is crucial to success.
        </p>
      </section>
    </div>
  );
};

export default Options;
