import React from "react";
import "../../styles/Subsections.css"; // ✅ Universal styling

const Foundations = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Foundations of Money</h1>

      <section>
        <h2>What Is Money?</h2>
        <p>
          Before you can master money, you need to understand what it is and—more importantly—what you want from it. Most people never ask themselves this, and that is why they stay stuck.
        </p>
        <p>
          This is a theme you will see throughout this content. Everyone's situation and goals are different, so you need to determine yours. You don’t have to know exactly what you want for the rest of your life, but you do need a vision—after all, you can’t build a house without a sketch.
        </p>
        <p>
          I constantly find myself being torn when making difficult choices. This is when I ask myself, "What do I believe in this moment to be best for my long-term goals?"
        </p>
        <p>Think about it:</p>
        <ul>
          <li>Do you want to make enough to backpack the world?</li>
          <li>Do you want to retire by 30?</li>
          <li>Do you want to be the sole provider for your family?</li>
          <li>Do you want to retire your parents?</li>
          <li>Do you want to buy a Lamborghini, a yacht, a mansion—or all of the above?</li>
        </ul>
        <p>
          There is no right or wrong answer. But whatever it is, you need to know what you are working toward and in what time frame. Your "why" will keep you motivated when things get tough. This means understanding not just the financial cost of your goal, but the sacrifices required to achieve it.
        </p>
      </section>

      <section>
        <h2>Money Is a Tool: Freedom, Choice, and Buying Back Time</h2>
        <p>
          Some people say money is superficial. It is not. Being obsessed with image or status is superficial. Money, when used correctly, is a tool for freedom.
        </p>
        <p>
          The financially literate understand that money is not a flex—it is leverage. They use money to avoid waking up to an alarm clock, reporting to a boss they hate, or being stuck in one location. Most importantly, they can say no—because they have a choice.
        </p>
        <p>
          First, buy back your freedom. Then, buy the luxuries.
        </p>
        <p>
          That does not mean you should not buy nice things. I love cars and watches and I fully intend on indulging in those once I have fully bought back my freedom.
        </p>
        <p>
          If your spending dictates your financial choices and locks you into a job or income level just to keep up, you have fallen into lifestyle inflation—a trap I will break down later.
        </p>
      </section>

      <section>
        <h2>Why Understanding Money Is Crucial</h2>
        <p>
          Most people never realize what I just outlined. They stay in consumer mode—constantly earning and spending but never truly building wealth. It is a hamster wheel.
        </p>
        <p>
          The real shift happens when you go from being a consumer to a producer. Consumers trade time for money and spend it. Producers make money work for them.
        </p>
        <p>
          Understanding money is not just about getting rich. It is about control. Control over:
        </p>
        <ul>
          <li>Your time</li>
          <li>Your stress levels</li>
          <li>How you live your life</li>
        </ul>
        <p>
          But here is the catch—it requires a shift in mindset and a willingness to make sacrifices upfront. You have to ask yourself, are you willing to make those changes, or will you stay on the wheel?
        </p>
      </section>
    </div>
  );
};

export default Foundations;
