import { useEffect } from "react";

const usePreloadImages = (imageUrls) => {
  useEffect(() => {
    imageUrls.forEach((url) => {
      const storedImg = localStorage.getItem(url);
      if (!storedImg) {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          localStorage.setItem(url, img.src); // Cache image
        };
      }
    });
  }, [imageUrls]);
};

export default usePreloadImages;
