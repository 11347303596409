import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./../styles/Navbar.css";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Close menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuOpen && !event.target.closest(".navbar")) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => document.removeEventListener("click", handleOutsideClick);
  }, [menuOpen]);

  return (
    <nav className="navbar">
      {/* ✅ Mobile: Make logo a button, but keep the same look and position */}
<img 
  src="/no-background.webp" 
  alt="Logo" 
  className="navbar-logo" 
  onClick={() => {
    if (window.innerWidth <= 768) {
      window.location.href = "/";
    }
  }}
  style={{ cursor: window.innerWidth <= 768 ? "pointer" : "default" }}
/>

      {/* ✅ Mobile Menu Button - Only visible on mobile */}
      <button 
        className="menu-toggle" 
        onClick={(e) => {
          e.stopPropagation();
          setMenuOpen(!menuOpen);
        }}
      >
        ☰
      </button>

      {/* ✅ Navigation Links (Hidden on Mobile, Always Visible on Desktop) */}
      <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
        <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>  
        <li><Link to="/introduction" onClick={() => setMenuOpen(false)}>Introduction</Link></li>
        <li><Link to="/learning" onClick={() => setMenuOpen(false)}>Learning</Link></li>
        <li><Link to="/practical-applications" onClick={() => setMenuOpen(false)}>Applications</Link></li>
        <li><Link to="/qa" onClick={() => setMenuOpen(false)}>Q&A</Link></li>
        <li><Link to="/socials" onClick={() => setMenuOpen(false)}>Socials</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;