import React from "react";
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const Gold = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Investing in Gold</h1>

      <section>
        <h2>What Gold Really Is</h2>
        <p>
          Gold has been a store of value for centuries. It is not tied to any single 
          economy, company, or financial system, making it one of the most independent 
          assets available. Unlike stocks and bonds, gold does not generate cash flow, 
          but it serves a different purpose—diversification, stability, and wealth 
          preservation.
        </p>
      </section>

      <section>
        <h2>Why Investors Use Gold</h2>

        <h3>Diversification</h3>
        <p>
          Gold behaves differently from stocks and bonds. When traditional assets decline, 
          gold often moves in the opposite direction or holds steady. This can help reduce 
          overall risk in a portfolio.
        </p>

        <h3>Inflation Protection</h3>
        <p>
          Inflation reduces the value of cash over time. Historically, as inflation rises, 
          gold prices tend to follow. Unlike paper currency, gold holds its value, making it 
          a potential hedge against declining purchasing power.
        </p>

        <h3>A Safe Haven During Uncertainty</h3>
        <p>
          Gold is sometimes called the "crisis commodity" because investors turn to it during 
          times of market instability. It does not rely on corporate profits, interest rates, 
          or government policies, making it a trusted fallback asset.
        </p>
      </section>

      <section>
        <h2>Ways to Invest in Gold</h2>
        <p>
          There are multiple ways to gain exposure to gold, each with its own benefits and 
          trade-offs.
        </p>

        <h3>1. Physical Gold</h3>
        <p>
          Buying gold bars, coins, or bullion provides direct ownership of the metal. However, 
          storage, insurance, and security costs should be considered.
        </p>

        <h3>2. Gold ETFs</h3>
        <p>
          Gold-backed ETFs offer a liquid and accessible way to invest. They trade like stocks 
          and track gold’s price movements, though investors do not own the physical gold itself.
        </p>

        <h3>3. Gold Mining Stocks & Funds</h3>
        <p>
          Investing in gold mining companies provides indirect exposure to gold prices. However, 
          these stocks are influenced by more than just gold prices—company performance, 
          production costs, and management decisions all play a role, making them more volatile.
        </p>

        <h3>4. Gold Futures & Derivatives</h3>
        <p>
          For experienced investors, futures and options provide leveraged exposure to gold price 
          movements. These strategies carry higher risk and require deep market knowledge.
        </p>
      </section>

      <section>
        <h2>Taxes on Gold Investments</h2>
        <p>
          Gold is taxed differently than stocks or bonds. Understanding how your holdings are taxed 
          is crucial before investing.
        </p>

        <h3>Physical Gold & Gold-Backed ETFs</h3>
        <ul>
          <li>Classified as collectibles by tax authorities.</li>
          <li>Subject to capital gains taxes upon sale.</li>
          <li>Short-term gains taxed as ordinary income.</li>
          <li>Long-term gains taxed at a maximum of 28%.</li>
        </ul>

        <h3>Gold Mining Stocks & ETFs</h3>
        <p>
          Taxed like regular stocks and mutual funds, with standard capital gains tax rates.
        </p>

        <h3>Gold in Retirement Accounts (IRAs)</h3>
        <p>
          Some forms of gold can be held in an IRA, but there are strict rules on which types qualify. 
          Always check IRS guidelines or consult a tax expert before adding gold to a retirement account.
        </p>
      </section>

      <section>
        <h2>Gold as an Investment</h2>
        <p>
          Gold is not an investment for fast returns—it is a tool for wealth preservation. 
          It does not produce income like stocks or bonds, but it offers a level of security 
          that other assets cannot.
        </p>
        <p>
          If your goal is diversification, inflation protection, or a safe-haven asset, gold 
          can play a role in your portfolio. However, it should be part of a broader strategy, 
          not a standalone focus.
        </p>
      </section>
    </div>
  );
};

export default Gold;