import React from "react";
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const SavingSpendingBudgeting = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Saving, Spending, and Budgeting</h1>

      <section>
        <h2>Saving</h2>
        <p>
          At its simplest, saving is spending less than you earn and setting that money aside for later. But if you want to actually get ahead, there is more to it.
        </p>

        <h3>Why Traditional Saving Does not Work</h3>
        <p>
          For most people, "saving" means putting money in a bank and letting it sit. But here is the truth: this will not get you ahead.
        </p>
        <p>Maybe in the past, it was a solid strategy. Not anymore.</p>
        <p>
          Yes, saving money is better than spending everything you earn—but if you have made it this far, you are not here to be "average." You want to get ahead. And keeping money in a bank will not do that.
        </p>

        <h3>Inflation and the Erosion of Purchasing Power</h3>
        <p>
          You might be wondering—if everyone says saving in a bank is good, why is it not? Here is the breakdown:
        </p>
        <ul>
          <li>Banks pay you interest on your deposits.</li>
          <li>But they loan out your money at much higher rates—making a profit off your cash.</li>
        </ul>
        <p><strong>In 2025:</strong></p>
        <ul>
          <li>The average savings account pays 0.01–0.10% interest.</li>
          <li>The average loan (mortgage, personal loan, credit card) charges 6.5–30% interest.</li>
          <li>Banks are making 6.4–29.99% profit off of your money.</li>
        </ul>
        <p>At first, you might think, "Well, at least I am making something." But you are actually losing money—because of inflation.</p>

        <h3>How Inflation Works</h3>
        <p><strong>Example:</strong> Today, $10 buys 1.35 lbs of steak. In a few years, that same $10 might only buy 1 lb. Your money has not changed—but its purchasing power has.</p>
        <p>
          Since 1913, the average U.S. inflation rate is 3.2% per year. That means if your savings are not growing by at least 3.2% annually, you are losing money in real terms.
        </p>

        <h3>The Real Purpose of Saving</h3>
        <p>
          Now that we have debunked the traditional saving myth, here is what saving should do:
        </p>
        <ul>
          <li>Keep up with and outpace inflation</li>
          <li>Help you build wealth over time</li>
          <li>Be an investment in your future</li>
        </ul>
        <p>Real saving is not just about hoarding cash—it is about making your money work for you.</p>

        <h3>Regular Contributions: The Key to Building Wealth</h3>
        <p>
          The best way to build wealth is through consistent, defined contributions into accounts that keep up with or outpace inflation. Automate your contributions so you do not have to think about it. Small, consistent actions compound over time—I will break this down later.
        </p>
      </section>

      <section>
        <h2>Spending</h2>
        <p>
          Saving and spending go hand in hand—you cannot talk about one without the other. The goal is not to avoid spending altogether. It is to spend in a way that moves you toward your long-term goals.
        </p>

        <h3>Needs vs. Wants: Being Honest About Your Spending</h3>
        <p>Spending falls into two basic categories:</p>
        <ul>
          <li><strong>Needs:</strong> Non-negotiable expenses required for living (food, rent, healthcare).</li>
          <li><strong>Wants:</strong> Things that enhance life but are not essential (fancy meals, designer clothes, bar nights).</li>
        </ul>
        <p>
          Categorizing your spending is simple if you are honest with yourself. Wants are not bad—but ask yourself: Is this purchase moving me closer to or further from my goals?
        </p>
      </section>

      <section>
        <h2>Budgeting</h2>
        <p>
          To successfully save, you need to budget. Budgeting helps you track what you are spending and saving, where your money is going, and whether you are on track for your long-term financial goals.
        </p>

        <h3>Budgeting = Achieving Goals</h3>
        <p>
          Think of budgeting as a roadmap—without one, you are just guessing where you will end up. As mentioned earlier, you need to start with the end goal in mind and work backward.
        </p>
        <p>
          For example, if you aim to build a net worth of $5 million in 10 years, break that down into yearly and monthly savings targets. This helps you determine exactly how much you need to save each month to stay on track.
        </p>

        <h3>Spending Awareness</h3>
        <p>
          Budgeting is not just about saving—it is about understanding how you spend. Unnecessary spending makes reaching long-term goals harder. Track your expenses honestly and ensure your spending aligns with your priorities.
        </p>

        <h3>Small Cuts + Increased Savings = Big Results</h3>
        <p>
          Once you are aware of your spending, identify areas where you can cut back. Every dollar you do not spend is a dollar working toward your financial freedom. The less you waste, the faster you reach your goals.
        </p>

        <h3>Delayed Gratification &amp; Life Balance</h3>
        <p>
          At this point, you might be thinking, "I will only spend on necessities so I can achieve my goals as fast as possible." While this approach accelerates progress, it comes with trade-offs.
        </p>
        <p>
          This concept is known as delayed gratification. However, life is not binary—it is about balance. If you sacrifice all short-term enjoyment for long-term fulfillment, only to face unexpected circumstances, was it worth it?
        </p>
        <p>
          Since some factors are out of your control, you need to find a balance between spending and saving—one that allows you to enjoy life while still making steady progress toward your goals. Every financial decision is a trade-off between now and the future. The key is intentionality—spend on what truly matters to you while ensuring your future self is taken care of.
        </p>
      </section>
    </div>
  );
};

export default SavingSpendingBudgeting;
