import React from "react";
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const TypesInvestments = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Types of Investments</h1>

      <section>
        <h2>Investment Portfolio</h2>
        <p>
          An investment portfolio is a collection of all the assets an individual or entity holds. To build a strong portfolio, it is essential to understand the three primary investment classes—each serving different purposes and balancing risk and return. This concept, known as diversification, helps create a resilient portfolio by combining assets that perform differently under various market conditions.
        </p>
        <p>
          Think of a portfolio like a company: a successful business needs a mix of technical experts, charismatic salespeople, and strong leaders—each playing a unique role. Likewise, a well-structured portfolio benefits from a blend of investment classes working together for growth and stability.
        </p>
        <p>The three main investment classes are:</p>
        <ul>
          <li><strong>Fixed Income (FI):</strong> Generally lower risk, providing stability.</li>
          <li><strong>Equities:</strong> Higher growth potential with increased volatility.</li>
          <li><strong>Alternative Assets (Alts):</strong> Diverse investments with unique risk-reward characteristics.</li>
        </ul>
      </section>

      <section>
        <h2>Fixed Income (FI)</h2>
        <p>
          Fixed income is often seen as the “boring” part of a portfolio because it typically offers lower returns. However, its stability makes it a critical component of long-term investing. The most common fixed-income investment is bonds, though the category includes other debt instruments as well.
        </p>
        <h3>How Bonds Work:</h3>
        <p>
          A bond is essentially a loan agreement where one party lends money to another in exchange for periodic interest payments and the return of the principal at maturity.
        </p>
        <ul>
          <li>You lend $100 to a company or government.</li>
          <li>They agree to pay you 5% interest annually for 5 years.</li>
          <li>At the end of 5 years, you get your $100 back plus the earned interest.</li>
        </ul>
        <p>
          Bonds are rated based on their risk of default. Lower-risk bonds (like U.S. Treasury bonds) offer lower interest rates, while higher-risk bonds (like corporate junk bonds) offer higher potential returns due to the increased chance of default.
        </p>
        <p>
          <strong>Why Fixed Income Matters:</strong> It provides stability and cushions losses when stocks or alternative assets decline, offers predictable income, and acts as a counterbalance to more volatile equities.
        </p>
      </section>

      <section>
        <h2>Equities (Stocks)</h2>
        <p>
          Equities represent ownership in a company—when you buy a stock, you own a piece of that business. Stocks have higher growth potential than fixed-income assets but also come with greater volatility.
        </p>
        <p>
          Equities generally perform well when the economy is strong, offering long-term appreciation and, in some cases, dividends.
        </p>
        <h3>Key Characteristics of Equities:</h3>
        <ul>
          <li>Higher potential returns than fixed income.</li>
          <li>More volatile—prices fluctuate based on market conditions.</li>
          <li>Historically, equities have outperformed most other asset classes over the long run.</li>
        </ul>
        <p>
          However, overexposure to equities can lead to excessive risk, especially during downturns.
        </p>
      </section>

      <section>
        <h2>Alternative Assets (Alts)</h2>
        <p>
          Alternative assets encompass a wide range of investments outside traditional stocks and bonds. These assets often provide unique diversification benefits, as they may perform well when traditional markets struggle.
        </p>
        <h3>Types of Alternative Assets:</h3>
        <ul>
          <li><strong>Digital Assets:</strong> Cryptocurrencies, NFTs, digital real estate.</li>
          <li><strong>Physical Assets:</strong> Precious metals (gold, silver), real estate, collectibles (cars, watches, art).</li>
          <li>
            <strong>Private Equity &amp; Venture Capital (PE/VC):</strong>
            <ul>
              <li>Private equity: Investments in companies not traded on public stock exchanges.</li>
              <li>Venture capital: Funding for early-stage startups, offering high risk and high reward.</li>
            </ul>
          </li>
        </ul>
        <p>
          While alternative assets can offer significant upside, they often come with liquidity constraints, regulatory risks, and volatility.
        </p>
        <p>
          <strong>Why Alternative Assets Matter:</strong> They can hedge against traditional market downturns, offer diversification, and provide access to unique investment opportunities.
        </p>
      </section>

      <section>
        <h2>Diversification: The Key to Risk Management</h2>
        <p>
          Diversification is the practice of spreading investments across different asset classes to reduce risk. No single investment will always perform well, and downturns are inevitable. A diversified portfolio ensures that when one asset class struggles, others may perform better, balancing overall performance.
        </p>
        <h3>Why Diversification Matters:</h3>
        <ul>
          <li>Protects against market volatility by not relying on a single asset.</li>
          <li>Increases the chances of consistent, long-term growth.</li>
          <li>Helps investors weather economic downturns without extreme losses.</li>
        </ul>
        <p>
          For example, digital assets may offer high returns but are highly volatile—balancing them with equities and bonds helps stabilize your portfolio. Conversely, an all-fixed-income portfolio may be too conservative to meet growth targets.
        </p>
        <p>
          A well-structured portfolio balances growth potential, stability, and diversification.
        </p>
      </section>

      <section>
        <h2>Summary</h2>
        <p>
          Investing is not about choosing a single asset class—it is about creating a strategic mix that aligns with your goals and risk tolerance. By understanding and incorporating Fixed Income, Equities, and Alternative Assets, you can build a resilient portfolio that adapts to market shifts while maximizing long-term returns. The key to smart investing is not avoiding risk—it is managing it. A diversified portfolio helps you navigate uncertainty while steadily growing your wealth over time.
        </p>
      </section>
    </div>
  );
};

export default TypesInvestments;
