import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

console.log("🔍 Supabase URL:", supabaseUrl);
console.log("🔍 Supabase Key:", supabaseAnonKey ? "✅ Loaded" : "❌ Undefined");

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error("Supabase environment variables are required.");
}

// ✅ Initialize Supabase
const supabase = createClient(supabaseUrl, supabaseAnonKey);
export default supabase;
