import React from "react";
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const Debt = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Escaping the Debt Trap</h1>

      <section>
        <h2>Why Debt is a Major Problem</h2>
        <p>
          Debt compounds quickly, and if left unchecked, it can spiral out of control. 
          High-interest debt—especially from credit cards—can make financial freedom 
          feel impossible.
        </p>

        <p>For example:</p>
        <ul>
          <li>
            A <strong>$1,000 credit card balance</strong> at <strong>30% annual interest</strong> grows to <strong>$20,258 in 10 years</strong> if you only make minimum payments.
          </li>
          <li>
            A <strong>$10,000 balance</strong> at the same rate balloons to <strong>$202,580</strong> in the same period.
          </li>
        </ul>

        <p>
        This assumes no additional charges are made to the card, and that the minimum payment only covers interest, meaning the principal remains untouched. This calculation follows the compound interest formula with monthly compounding, as credit cards traditionally do. This is compound interest working against you. The longer you carry debt, 
          the harder it becomes to escape.
        </p>
      </section>

      <section>
        <h2>If You’re in Debt, That’s Your Priority</h2>
        <p>
          If you have high-interest debt, paying it off should be your primary focus.
        </p>
        <ul>
          <li>No investment will consistently outpace a 30% interest rate.</li>
          <li>Every dollar of interest paid cancels out potential investment gains.</li>
          <li>The best return you can make is eliminating debt.</li>
        </ul>

        <p>
          If you receive extra money—whether from a paycheck, bonus, or tax refund—
          use it to pay down your debt before thinking about saving or investing.
        </p>
      </section>

      <section>
        <h2>How to Get Out of Debt</h2>

        <h3>1. Lower Your Interest Rate</h3>
        <p>
          The higher your interest rate, the longer you remain stuck. If your credit 
          card charges 30% interest, you may qualify for a lower-rate debt consolidation 
          option.
        </p>
        <p>For example:</p>
        <ul>
          <li>
            Instead of paying <strong>30% on a $1,000 balance</strong>, taking out a 
            personal loan at <strong>10%</strong> significantly reduces interest costs.
          </li>
          <li>This allows you to pay down the principal faster.</li>
        </ul>
        <p>A lower interest rate alone will not solve the problem, but it makes repayment more manageable.</p>

        <h3>2. Pay Off Debt Aggressively</h3>
        <p>No matter your situation, eliminating debt should be the goal.</p>
        <ul>
          <li>Pay more than the minimum to reduce principal faster.</li>
          <li>Cut unnecessary expenses and redirect those savings toward debt.</li>
          <li>Increase income through side work to accelerate payments.</li>
        </ul>

        <h3>3. Avoid the Debt Cycle</h3>
        <p>
          Using a lower-interest loan to replace credit card debt can provide relief, 
          but it is not a long-term solution. The goal is not to trade one type of debt 
          for another but to eliminate it entirely.
        </p>
        <p>
          If spending habits do not change, the cycle continues. Reducing interest rates 
          only helps if paired with a plan to pay off balances completely.
        </p>
      </section>

      <section>
        <h2>Summary</h2>
        <p>
          Debt is not just a financial burden—it is an obstacle to wealth-building. 
          Every day you remain in debt, interest works against you.
        </p>
        <p>
          The fastest way to build financial stability is to eliminate high-interest 
          debt. Once you are debt-free, you can shift your focus to saving, investing, 
          and long-term financial growth.
        </p>
      </section>
    </div>
  );
};

export default Debt;
