import React from "react";
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const CreditDebt = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Credit/Debit &amp; Debt</h1>

      <section>
        <h2>Good Debt vs. Bad Debt</h2>
        <p>
          Not all debt is bad, despite its negative reputation. The key is understanding when debt serves as a tool for financial growth and when it becomes a trap. Debt is a form of leverage—it allows you to borrow money to access assets or investments that could otherwise be out of reach.
        </p>
      </section>

      <section>
        <h2>The Difference</h2>
        <p>
          To understand why some debt is beneficial, we first need to examine why debt has a bad reputation and how it can become dangerous if misused. Because of factors like the time value of money and opportunity cost (covered later), borrowing always comes with a cost. Lenders charge interest to compensate for the income they forgo by lending to you instead of investing elsewhere.
        </p>
        <p>
          When you borrow, you repay both the principal (the initial loan amount) and interest, which depends on the loan's rate and term. For example, if you borrow $1,200 at a 10% annual interest rate, you will owe $120 in interest over the year, bringing your total repayment to $1,320. Misusing debt can quickly lead to financial losses. The key is ensuring your returns exceed the cost of interest.
        </p>
      </section>

      <section>
        <h2>Bad Debt</h2>
        <p>
          Many people overspend on credit cards, carrying a balance they cannot fully pay off. Once interest kicks in—often 25–30% or more annually—it becomes nearly impossible to outpace that rate. This cycle of increasing debt makes it harder to pay off over time.
        </p>
        <p>
          Bad debt is any loan where the interest you are paying is higher than what you are generating with the borrowed capital. This includes high-interest consumer debt, payday loans, and unnecessary purchases financed with credit.
        </p>
      </section>

      <section>
        <h2>Not All Debt Is Bad</h2>
        <p>
          Now that we have covered bad debt, let us look at the other side—using debt strategically to build wealth, also known as “good debt.” Good debt occurs when the money earned from borrowing exceeds the interest paid.
        </p>
        <h3>Examples of Potential Good Debt</h3>
        <ul>
          <li><strong>Margin Trading:</strong> Investors borrow capital to trade, aiming to earn more than the principal and interest owed.</li>
          <li><strong>Real Estate:</strong> An investor takes out a mortgage on a rental property. If rental income covers loan payments and generates additional profit, the debt is working in their favor.</li>
        </ul>
        <p>
          However, both strategies carry risks. If trades do not go as intended or tenants do not pay rent, you could lose money while still owing interest on the loan.
        </p>
      </section>

      <section>
        <h2>Credit Cards vs. Debit Cards</h2>
        <p>
          After learning about bad debt, you might think, "I will just use a debit card and avoid credit altogether." While that sounds safe, it is not always the best strategy. Let us break down how debit and credit cards work—and why one is often the better choice.
        </p>

        <h3>Debit Card Pros &amp; Cons</h3>
        <p>
          A debit card functions like electronic cash—when you make a purchase, the money is immediately withdrawn from your linked bank account. Since there is no borrowing involved, you cannot spend more than what is in your account (unless you have overdraft protection, which often comes with additional fees).
        </p>
        <p><strong>Pros:</strong></p>
        <ul>
          <li>No risk of debt accumulation—you can only spend what you have.</li>
          <li>No interest charges.</li>
        </ul>
        <p><strong>Cons:</strong></p>
        <ul>
          <li>Limited fraud protection – If someone steals your debit card, the money is withdrawn directly from your account, making recovery difficult.</li>
          <li>No credit-building benefits – Debit cards do not contribute to your credit history or score.</li>
          <li>Fewer perks – Unlike credit cards, debit cards do not offer rewards, cashback, or travel benefits.</li>
        </ul>
        <p>In part, because of these drawbacks, I rarely use or even carry a debit card.</p>
      </section>

      <section>
        <h2>Choosing the Right Bank</h2>
        <p>
          If you prefer using a debit card, that is completely fine—building responsible financial habits is key. However, since banks profit from your deposits, you should ensure they provide real benefits in return.
        </p>

        <h3>Look for Low Fees &amp; Perks</h3>
        <p>
          Banks use your money to generate profits, so you should get something in return. Many banks offer incentives to attract customers, such as:
        </p>
        <ul>
          <li>Account opening bonuses</li>
          <li>No monthly maintenance fees</li>
          <li>ATM fee reimbursements</li>
          <li>No foreign transaction fees</li>
          <li>Strong customer support</li>
        </ul>
        <p>
          You should incur as few fees as possible when banking. Your relationship with a bank should be mutually beneficial—do not let them take advantage of you with unnecessary fees and poor service.
        </p>
      </section>

      <section>
        <h2>Credit Card Pros &amp; Cons</h2>
        <p>Used responsibly, credit cards offer significant advantages over debit cards.</p>
        <p><strong>Pros:</strong></p>
        <ul>
          <li>Fraud protection – Credit card companies have strong fraud detection measures, and you are typically not liable for fraudulent charges.</li>
          <li>Rewards &amp; perks – Many credit cards offer cashback, travel rewards, and access to events or lounges.</li>
          <li>Short-term, interest-free borrowing – If you pay off your balance in full each month, you can essentially use the bank’s money for free for up to 30 days.</li>
          <li>Credit-building benefits – Responsible credit card use helps improve your credit score.</li>
        </ul>
        <p><strong>Cons:</strong></p>
        <ul>
          <li>High interest rates – Carrying a balance can lead to expensive interest charges.</li>
          <li>Overspending risk – Easy access to credit can encourage spending beyond your means.</li>
        </ul>
      </section>

      <section>
        <h2>Building Credit</h2>
        <h3>What Shapes Your Credit Score?</h3>
        <ul>
          <li>300–579: “Poor”</li>
          <li>580–669: “Fair”</li>
          <li>670–739: “Good”</li>
          <li>740–799: “Very Good”</li>
          <li>800–850: “Excellent”</li>
        </ul>
        <h3>How to Maintain a Strong Credit Score</h3>
        <ul>
          <li>Pay bills on time—every time.</li>
          <li>Keep utilization low (ideally 10-30%).</li>
          <li>Limit new credit applications.</li>
          <li>Keep older accounts open.</li>
          <li>Maintain a diverse credit mix.</li>
        </ul>
      </section>

      <section>
        <h2>Summary</h2>
        <p>Mastering debt, credit cards, and credit scores is key to financial success. Used wisely, credit cards are tools—not traps. Financial success is not about avoiding debt—it is about controlling it.</p>
      </section>
    </div>
  );
};

export default CreditDebt;
