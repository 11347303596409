import React, { useEffect, useState } from "react";
import supabase from "../../supabaseConfig";  
import "../../styles/Subsections.css";

const QAAnswer = () => {
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchAnsweredQuestions = async () => {
      try {
        const { data, error } = await supabase
          .from("questions")
          .select("*")
          .eq("approved", true)
          .not("answer", "is", null) // Ensures the answer is not null
          .neq("answer", ""); // Ensures the answer is not an empty string

        if (error) throw error;

        setAnsweredQuestions(data || []);
      } catch (error) {
        console.error("Error fetching answered questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnsweredQuestions();
  }, []);

  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Answered Questions</h1>
      {loading && <p className="loading-text">Loading answered questions...</p>}
      {!loading && answeredQuestions.length === 0 && (
        <p className="no-questions">No answered questions yet.</p>
      )}
      {!loading &&
        answeredQuestions.map((q) => (
          <div key={q.id} className="qa-answer-item">
            <p className="question-text"><strong>Q:</strong> {q.question}</p>
            <p className="answer-text"><strong>A:</strong> {q.answer}</p>
          </div>
        ))}

      {/* ✅ Footer with Disclaimer */}
      <footer className="footer">
        <p className="footer-text">
          Disclaimer: This website is for educational purposes only and does not provide financial advice. 
          <span className="footer-link" onClick={() => setIsModalOpen(true)}> Read full disclaimer</span>.
        </p>
      </footer>

      {/* ✅ Disclaimer Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Disclaimer</h2>
            <p>
              The information provided on <strong>Modern Money Methods</strong> is for educational and informational purposes only. Nothing on this Website should be construed as financial, investment, legal, or tax advice. The content is designed to help users improve their financial literacy, but it does not replace professional advice from a licensed financial professional.
            </p>
            <p>
              <strong>No Financial Advice:</strong> Modern Money Methods does not provide personalized financial recommendations. Any financial decisions you make based on the content of this Website are done at your own risk. Always consult with a certified financial advisor, accountant, or attorney before making financial decisions.
            </p>
            <p>
              <strong>No Liability:</strong> Modern Money Methods and its creators are not responsible for any financial losses, decisions, or actions taken based on the content of this Website. By using this Website, you acknowledge that you assume full responsibility for your own financial decisions.
            </p>
            <button className="close-modal" onClick={() => setIsModalOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QAAnswer;