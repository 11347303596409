import React, { useEffect, useState } from "react";
import supabase from "../../supabaseConfig";
import "../../styles/Subsections.css";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const QAList = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userVotes, setUserVotes] = useState({}); // Track user votes locally

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const { data, error } = await supabase
          .from("questions")
          .select("id, question, upvotes")
          .eq("approved", true)
          .order("upvotes", { ascending: false });

        if (error) throw error;

        setQuestions(data || []);

        // Load stored votes
        const storedVotes = JSON.parse(localStorage.getItem("userVotes")) || {};
        setUserVotes(storedVotes);
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const handleVote = async (id, type) => {
    if (userVotes[id]) {
      console.log(`❌ Already voted on ${id}`);
      return; // Prevent multiple votes
    }

    const question = questions.find((q) => q.id === id);
    if (!question) return;

    let newVotes = type === "up" ? question.upvotes + 1 : Math.max(0, question.upvotes - 1);

    console.log(`🔄 Updating vote for ${id} to ${newVotes}`);

    // ✅ Update Supabase
    const { error } = await supabase
      .from("questions")
      .update({ upvotes: newVotes })
      .eq("id", id);

    if (error) {
      console.error("❌ Supabase Error:", error);
      return;
    }

    console.log("✅ Supabase Update Success");

    // ✅ Fetch the updated data from Supabase
    const { data: updatedQuestion, error: fetchError } = await supabase
      .from("questions")
      .select("upvotes")
      .eq("id", id)
      .single();

    if (fetchError) {
      console.error("❌ Error fetching updated votes:", fetchError);
      return;
    }

    console.log(`✅ Updated votes for ${id}:`, updatedQuestion.upvotes);

    // ✅ Update UI with new vote count
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === id ? { ...q, upvotes: updatedQuestion.upvotes } : q
      )
    );

    // ✅ Store user vote in local storage
    const updatedVotes = { ...userVotes, [id]: type };
    setUserVotes(updatedVotes);
    localStorage.setItem("userVotes", JSON.stringify(updatedVotes));
  };

  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Top Questions</h1>
      {loading && <p className="loading-text">Loading questions...</p>}
      {!loading && questions.length === 0 && (
        <p className="no-questions">No approved questions yet.</p>
      )}
      {!loading &&
        questions.map((q) => (
          <div key={q.id} className="question-item">
            <p className="question-text">{q.question}</p>
            <div className="vote-buttons">
              <button
                className={`vote-button upvote ${userVotes[q.id] ? "disabled" : ""}`}
                onClick={() => handleVote(q.id, "up")}
                disabled={userVotes[q.id]}
              >
                <FaChevronUp className="vote-icon up-icon" />
              </button>
              <span className="vote-count">{q.upvotes}</span>
              <button
                className={`vote-button downvote ${userVotes[q.id] ? "disabled" : ""}`}
                onClick={() => handleVote(q.id, "down")}
                disabled={userVotes[q.id]}
              >
                <FaChevronDown className="vote-icon down-icon" />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default QAList;
