import React from "react";
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const InvestmentConcepts = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Investment Concepts</h1>

      <section>
        <h2>Investment Concepts Introduction</h2>
        <p>
          This section ties together key terms we have covered and introduces foundational concepts essential for growing your financial literacy. Understanding these ideas will help you make informed financial decisions and build long-term wealth.
        </p>
      </section>

      <section>
        <h2>Time Value of Money (TVM)</h2>
        <p>
          As previously explained, money can generate more capital when properly invested. The Time Value of Money (TVM) illustrates this principle.
        </p>
        <p>
          At its core, TVM states that a dollar today is worth more than a dollar in the future because today’s dollar has earning potential. If invested, it can grow over time. This is why lenders charge interest—when they lend money, they incur an opportunity cost since they could have been using that money to generate returns elsewhere.
        </p>
        <p>
          Financially literate individuals rarely let money sit idle. Instead, they invest it in vehicles that generate returns. Banks and creditors follow this principle by using deposited funds to issue loans, profiting from money that is not even their own. If you are not making your money work for you, you are not only missing out on potential earnings—you are also losing purchasing power due to inflation. Put your money to work. Smart investing ensures your wealth grows rather than erodes over time.
        </p>
      </section>

      <section>
        <h2>Exponential Growth &amp; The Power of Compounding</h2>
        <p>
          The earlier you start investing, the greater your returns will be. Many people underestimate the true power of exponential growth, assuming they can "make up for lost time" later. However, the math proves otherwise.
        </p>

        <h3>Understanding Exponential Growth</h3>
        <p>
          Unlike linear growth (which adds a fixed amount over time), exponential growth accelerates because returns are compounded. This means your earnings generate additional earnings, snowballing over time.
        </p>

        <p>Let us look at an example:</p>
        <ul>
          <li>
            You invest $100,000 at age 25 in the S&amp;P 500, which has historically averaged a 10% annual return. You make no further contributions and leave it untouched for 40 years until retirement at 65.
            <br />
            <strong>Final balance:</strong> $4,525,925.56
          </li>
          <li>
            If you also contribute $1,000 per month over those 40 years:
            <br />
            <strong>Final balance:</strong> $9,837,036.22
          </li>
          <li>
            Now, compare that to investing later in life:
            <ul>
              <li>
                Investing $100,000 at age 45 for 20 years:
                <br />
                <strong>Final balance:</strong> $672,749.99
              </li>
              <li>
                Investing $500,000 at age 55 for 10 years:
                <br />
                <strong>Final balance:</strong> $1,296,871.23
              </li>
              <li>
                Even if you invest $1,000,000 at 55 and contribute $10,000 per month for 10 years, you would still only reach $4,506,233.41—less than what you would get by starting early with much less.
              </li>
            </ul>
          </li>
        </ul>

        <p>
          As you can see, starting early is far more impactful than trying to catch up later. Compounding works best over long periods, as seen in these examples—delaying could cost you millions of potential gains. What you may think of as small investments can grow to be very significant. These were game-changing realizations for me. Understanding compounding gave me a new perspective on the urgency of investing.
        </p>
      </section>

      {/* 🔹 Additional Disclaimer Section */}
      <section className="disclaimer-section">
        <div className="disclaimer-container">
          <h2 className="disclaimer-title">Exponential Growth Calculator</h2>
          <div className="disclaimer-button-container">
            <a
              href="https://www.investor.gov/"
              target="_blank"
              rel="noopener noreferrer"
              className="disclaimer-button"
            >
              Open Calculator
            </a>
          </div>
          <div className="disclaimer-text-container">
            <p className="disclaimer-text">
              <em>
                Source: <a href="https://www.investor.gov/" target="_blank" rel="noopener noreferrer">Investor.gov</a>. This information is provided by a third party. We are not affiliated with or responsible for its accuracy.
              </em>
            </p>
          </div>
        </div>
      </section>

    </div>
  );
};

export default InvestmentConcepts;
