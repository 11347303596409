import React from "react";
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const RiskManagement = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Risk Management: Navigating Uncertainty in Investing</h1>

      <section>
        <h2>What Risk Management Is</h2>
        <p>
          Risk management is a fundamental aspect of investing, ensuring that you are adequately compensated for the risks you take while minimizing potential losses. Because risk tolerance is highly personal, there is no universal approach—each investor must develop a framework tailored to their unique financial situation, goals, and comfort level with uncertainty.
        </p>
        <p>
          This section provides a high-level overview of risk management, but I strongly encourage further research to refine your personal strategy. More in-depth discussions on specific risk management techniques will be covered in future sections.
        </p>
      </section>

      <section>
        <h2>The Core Principle: Risk vs. Reward</h2>
        <p>
          Investing always involves risk, but the key is ensuring that the potential rewards justify the level of risk taken. This leads to the concept of the risk/reward ratio—for every additional unit of risk, there should be a proportionate increase in potential return.
        </p>
        <p>Effective risk management involves:</p>
        <ul>
          <li>Identifying risks associated with an investment.</li>
          <li>Mitigating risks through strategies like diversification.</li>
          <li>Ensuring fair compensation for the risks you take.</li>
        </ul>
        <p>
          For example, a high-risk speculative investment should offer a potential return that makes the risk worthwhile. Conversely, a low-risk investment (such as bonds) will offer lower but more stable returns.
        </p>
      </section>

      <section>
        <h2>Types of Investment Risks</h2>
        <p>
          Every investment carries some degree of risk. Understanding these risks allows you to make informed decisions and structure your portfolio wisely.
        </p>
        <ul>
          <li>
            <strong>Market Risk:</strong> The risk of broad market downturns, such as recessions or stock market crashes. This affects nearly all investments to some extent, making diversification crucial.
          </li>
          <li>
            <strong>Credit Risk:</strong> The risk that a borrower (such as a company or government) fails to repay debt (e.g., bonds). Higher-risk bonds offer greater interest rates but come with an increased chance of default.
          </li>
          <li>
            <strong>Liquidity Risk:</strong> The risk of not being able to sell an investment quickly or at a fair price when needed. Some assets, like real estate or private equity, have low liquidity.
          </li>
          <li>
            <strong>Inflation Risk:</strong> The risk that inflation erodes purchasing power, diminishing the real value of investment returns. Cash savings and low-interest fixed-income investments are particularly vulnerable.
          </li>
          <li>
            <strong>Political &amp; Regulatory Risk:</strong> Risks stemming from government policies, regulations, or global events (e.g., tax law changes, new financial regulations, or geopolitical conflicts) can heavily impact industries such as energy, technology, and healthcare.
          </li>
        </ul>
        <p>
          Since no investment is risk-free, proper risk management helps reduce exposure and ensure that risks are worth the potential rewards.
        </p>
      </section>

      <section>
        <h2>The Risk Curve: Balancing Risk and Return</h2>
        <p>The risk curve visualizes the relationship between risk and potential return:</p>
        <ul>
          <li>Lower-risk investments (e.g., bonds, index funds) generally offer lower returns but greater stability.</li>
          <li>Higher-risk investments (e.g., individual stocks, venture capital, cryptocurrency) have higher potential returns but come with greater uncertainty.</li>
        </ul>
        <p>Your goal is to position yourself on the curve where the return justifies the risk—not taking on excessive risk without proper reward.</p>
      </section>

      <section>
        <h2>Determining Your Risk Tolerance</h2>
        <p>Your risk tolerance is personal and depends on various factors. Answering the following questions can help you assess where you stand:</p>
        <ul>
          <li>Do you have financial dependents? (Children, spouse, aging parents)</li>
          <li>What is your age? (Younger investors can often afford more risk.)</li>
          <li>When will you need the money you are investing? (Short-term vs. long-term goals.)</li>
          <li>Would losing this money significantly impact your lifestyle?</li>
        </ul>
        <p>
          Many investors make the mistake of chasing the highest returns without considering their ability to handle losses. A devastating financial setback can be difficult to recover from, making risk assessment just as important as return potential.
        </p>
      </section>

      <section>
        <h2>Risk Tolerance vs. Age</h2>
        <p>While risk tolerance is individual, age plays a significant role in determining how much risk is appropriate.</p>
        
        <h3>Younger Investors (Higher Risk Tolerance)</h3>
        <ul>
          <li>More time to recover from market downturns.</li>
          <li>Fewer financial obligations (in most cases).</li>
          <li>Higher earning potential to recoup losses over time.</li>
          <li>Can take advantage of compounding returns with long-term investments.</li>
        </ul>

        <h3>Older Investors (Lower Risk Tolerance)</h3>
        <ul>
          <li>Less time to recover from financial losses.</li>
          <li>More responsibilities (e.g., family, mortgage, retirement planning).</li>
          <li>Larger capital at risk, meaning losses are more significant in dollar terms.</li>
          <li>Greater need for stability and income-generation (e.g., dividend stocks, bonds).</li>
        </ul>
        <p>
          While younger investors can afford higher risk, it is essential to still diversify and mitigate downside exposure. Meanwhile, older investors may shift toward safer assets as they approach retirement, prioritizing capital preservation.
        </p>
      </section>

      <section>
        <h2>Summary</h2>
        <p>When developing a framework for your own risk management, remember the following:</p>
        <ul>
          <li>Understand the risks you are taking before investing.</li>
          <li>Ensure the rewards justify the risk.</li>
          <li>Use strategies like diversification to reduce exposure.</li>
          <li>Assess your risk tolerance honestly.</li>
        </ul>
        <p>
          By taking a structured approach to risk, you can build a resilient portfolio that aligns with your goals while protecting yourself from unnecessary losses. The key is not avoiding risk entirely—it is managing it wisely.
        </p>
      </section>
    </div>
  );
};

export default RiskManagement;
